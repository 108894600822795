











































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Props, Global } from '@/store';

@Component({
    components: {}
})

export default class Contactor extends Vue {
    @Prop() device;
    @Prop() mqtt;
    @Prop() extentioned_teltonica;
    @Prop() streamControl;
    @Prop() item;
    @Prop() outputItems;
    @Prop() parentId;
    @Prop() grandParentId;
    @Prop() childItems;
    @Prop() scheduleProp;
    @Prop() mqtt_version;

    @Props.State('list') projectProperties;
    @Props.Action('editProp') editProp;
    @Global.State('lang') lang;
    @Global.State('readonly_user') readonly_user;

    contactorIcon = require('@/assets/images/cabinet_details_icons/contactor.png');
    addChildIcon = require('@/assets/images/cabinet_details_icons/add_child.png');

    outputsStatus = {};
    timezone = 'Asia/Tel_Aviv';
    deviceScheduleProp = null;
    activeSchedule = {};
    schedulesOff = {};
    schedulesOn = {};
    duskOffsetHours = {};
    duskOffsetMinutes = {};
    dawnOffsetHours = {};
    dawnOffsetMinutes = {};
    isAstronomical = {};
    duskOffsetSign = {};
    dawnOffsetSign = {};
    schedules = {};
    errorMsg = {on: '', off: ''};
    errorMsg_1 = 'You must insert on time';
    errorMsg_2 = 'You must insert off time';
    errorMsg_3 = 'On and Off time must be different';
    desc = {};
    showDuskErrorHours = false;
    showDuskErrorMinutes = false;
    duskError = '';
    showDawnErrorHours = false;
    showDawnErrorMinutes = false;
    dawnError = '';
    previous_output = null;

    mounted() {
        this.updateOutputsStatus();
        const project_timezone = this.projectProperties.find((el) => el.name === 'project.timezone');
        this.timezone = project_timezone && project_timezone.value && project_timezone.value.timezone || this.timezone;
        this.deviceScheduleProp = this.scheduleProp;
        this.resetToPropsValues();
        this.previous_output = this.item.control;
    }

    @Watch('streamControl', {deep: true, immediate: true})
    updateOutputsStatus() {
        const message = this.streamControl;
        if (message) {
            Object.entries(message).forEach(([output, status]) => {
                const channel = Number(output);
                let parsed_status;
                try{
                    parsed_status = this.extentioned_teltonica ? Number(status) : status;
                }catch (e){
                    parsed_status = status;
                }

                this.outputsStatus = {...this.outputsStatus, [channel]: parsed_status };
            });
        }
    }

    changeStatus(control, newStatus) {
        const mqtt_publish_data = {
            version_num: this.mqtt_version,
            device_id: this.device.id,
            stream_id: '',
            message_type: 'cmd',
            format: 'minimal',
            stream_value: '',
            options: {
                retain: false,
                qos: 1
            }
        };

        if (this.extentioned_teltonica){
            this.outputsStatus[control] = newStatus ? 1 : 0;
            const value = this.outputsStatus[control].toString();
            mqtt_publish_data.stream_id = this.mqtt_version === 3 ? `${this.device.id}.relay_${control}` : `relay_${control}`;
            mqtt_publish_data.stream_value = value;
        }else {
            this.outputsStatus[control] = newStatus;
            const msg = { content: this.outputsStatus};
            mqtt_publish_data.stream_id = this.mqtt_version === 3 ? `${this.device.id}.control` : 'control';
            mqtt_publish_data.stream_value = JSON.stringify(msg);
        }

        this.mqtt.publishToSingleDevice(mqtt_publish_data);
    }

    outputChanged(item, event) {
        this.$emit('outputChanged', item, event);
        if (this.previous_output) this.setToFirstValue(event);
    }

    setToFirstValue(output){
        this.activeSchedule[output] = this.activeSchedule[this.previous_output];
        this.isAstronomical[output] = this.isAstronomical[this.previous_output];
        if (this.isAstronomical[this.previous_output]){
            this.duskOffsetHours[output] = this.duskOffsetHours[this.previous_output];
            this.duskOffsetMinutes[output] = this.duskOffsetMinutes[this.previous_output];
            this.dawnOffsetHours[output] = this.dawnOffsetHours[this.previous_output];
            this.dawnOffsetMinutes[output] = this.dawnOffsetMinutes[this.previous_output];
            this.duskOffsetSign[output] = this.duskOffsetSign[this.previous_output];
            this.dawnOffsetSign[output] = this.dawnOffsetSign[this.previous_output];
            this.schedulesOn[output] = '';
            this.schedulesOff[output] = '';
        }else {
            this.duskOffsetHours[output] = '';
            this.duskOffsetMinutes[output] = '';
            this.dawnOffsetHours[output] = '';
            this.dawnOffsetMinutes[output] = '';
            this.duskOffsetSign[output] = '';
            this.dawnOffsetSign[output] = '';
            this.schedulesOn[output] = this.schedulesOn[this.previous_output];
            this.schedulesOff[output] = this.schedulesOff[this.previous_output];       
        }

        this.showDawnErrorHours = false;
        this.showDawnErrorMinutes = false;
        this.showDuskErrorHours = false;
        this.showDuskErrorMinutes = false;
        this.dawnError = '';
        this.duskError = '';
    }

    deleteElement(id, parentId, grandParentId) {
        this.$emit('deleteElement', id, parentId, grandParentId);
    }

    addElement(el, item) {
        this.$emit('addElement', el, item);
    }

    typeIsChanged() {
        if (this.item.type === 'Monitored') {
            this.addElement('Line Monitor', this.item);
        }
    }

    get configurationItems(){
        return [
            { text: this.$t('1=on/0=off'), value: '1=on/0=off' },
            { text: this.$t('1=off/0=on'), value: '1=off/0=on' }
        ];
    }

    resetToPropsValues() {
        const activeSchedule = {};
        const isAstronomical = {};
        const schedulesOn = {};
        const schedulesOff = {};
        const duskOffsetHours = {};
        const duskOffsetMinutes = {};
        const dawnOffsetHours = {};
        const dawnOffsetMinutes = {};
        const duskOffsetSign = {};
        const dawnOffsetSign = {};
    
        Object.entries(this.deviceScheduleProp).forEach(([output, value]) => {
            if (output === 'timezone') return;

            activeSchedule[output] = value['active'] || false;
            isAstronomical[output] = value['is_astronomical'] || false;
            if (isAstronomical[output]) {
                if (value['dusk'] && value['dusk'].includes(':')) {
                    const [hours, minutes] = value['dusk'].split(':');
                    [duskOffsetSign[output], duskOffsetHours[output]] = hours.includes('+') || hours.includes('-') ? [hours[0], hours.slice(1)] : ['+', hours];
                    duskOffsetMinutes[output] = minutes;
                }else {
                    duskOffsetHours[output] = '';
                    duskOffsetMinutes[output] = '';
                    duskOffsetSign[output] = '+';
                }

                if (value['dawn'] && value['dawn'].includes(':')) {
                    const [hours, minutes] = value['dawn'].split(':');
                    [dawnOffsetSign[output], dawnOffsetHours[output]] = hours.includes('+') || hours.includes('-') ? [hours[0], hours.slice(1)] : ['+', hours];
                    dawnOffsetMinutes[output] = minutes;
                }else {
                    dawnOffsetHours[output] = '';
                    dawnOffsetMinutes[output] = '';
                    dawnOffsetSign[output] = '+';
                }

                schedulesOn[output] = '';
                schedulesOff[output] = ''; 
            }else {
                duskOffsetHours[output] = '';
                duskOffsetMinutes[output] = '';
                dawnOffsetHours[output] = '';
                dawnOffsetMinutes[output] = '';
                duskOffsetSign[output] = '+';
                dawnOffsetSign[output] = '+';
                schedulesOn[output] = value['on'] || '';
                schedulesOff[output] = value['off'] || '';
            }
        });
        for (let i = 1; i <= 4; i++) {
            if (!Object.keys(schedulesOn).includes(i.toString())) {
                schedulesOn[i.toString()] = '';
                schedulesOff[i.toString()] = '';
                activeSchedule[i.toString()] = false;
                this.deviceScheduleProp[i.toString()] = '';
            }
        }
        Object.entries(isAstronomical).forEach((item) => {
            if (item[1] === undefined) {
                isAstronomical[item[0]] = false;
            }
        });
        this.schedulesOn = schedulesOn;
        this.schedulesOff = schedulesOff;
        this.duskOffsetHours = duskOffsetHours;
        this.duskOffsetMinutes = duskOffsetMinutes;
        this.dawnOffsetHours = dawnOffsetHours;
        this.dawnOffsetMinutes = dawnOffsetMinutes;
        this.activeSchedule = activeSchedule;
        this.isAstronomical = isAstronomical;
        this.duskOffsetSign = duskOffsetSign;
        this.dawnOffsetSign = dawnOffsetSign;
        this.showDawnErrorHours = false;
        this.showDawnErrorMinutes = false;
        this.showDuskErrorHours = false;
        this.showDuskErrorMinutes = false;
        this.dawnError = '';
        this.duskError = '';
    }

    validateNumbers(e, min, max, type, output_num) {
        if (parseFloat(e.target.value) < parseFloat(e.target.min) || parseFloat(e.target.value) > parseFloat(e.target.max)) {
            if (type === 'duskHours') {
                this.duskError = `number has to be between ${min} to ${max}`;
                this.showDuskErrorHours = true;
            }
            else if (type === 'duskMinutes') {
                this.duskError = `number has to be between ${min} to ${max}`;
                this.showDuskErrorMinutes = true;
            } 
            else if (type === 'dawnMinutes') {
                this.dawnError = `number has to be between ${min} to ${max}`;
                this.showDawnErrorMinutes = true;
            }
            else if (type === 'dawnHours') {
                this.dawnError = `number has to be between ${min} to ${max}`;
                this.showDawnErrorHours = true;
            }
        }
        else {
            if (type === 'duskHours') {
                this.duskError = '';
                this.showDuskErrorHours = false;
            }
            else if (type === 'duskMinutes') {
                this.duskError = '';
                this.showDuskErrorMinutes = false;
            } 
            else if (type === 'dawnMinutes') {
                this.dawnError = '';
                this.showDawnErrorMinutes = false;
            }
            else if (type === 'dawnHours') {
                this.dawnError = '';
                this.showDawnErrorHours = false;
            }
        }
        const time_value = type.includes('dusk') 
            ? `${this.duskOffsetSign[output_num]}${this.duskOffsetHours[output_num]}:${this.duskOffsetMinutes[output_num]}`
            : `${this.dawnOffsetSign[output_num]}${this.dawnOffsetHours[output_num]}:${this.dawnOffsetMinutes[output_num]}`;
        this.$emit('updateSchedule', output_num, type.includes('dusk') ? 'dusk' : 'dawn', time_value);
    }

    clearErrorMsg(output_num, value){
        this.errorMsg.on = '';
        this.errorMsg.off = '';
        this.$emit('updateSchedule', output_num, 'astronomical', value);
    }

    updateErrorMsg(output_num, time_field){
        if (this.errorMsg.on === this.errorMsg_3) this.errorMsg.on = '';

        if (this.schedulesOn[output_num] === this.schedulesOff[output_num] && this.schedulesOn[output_num] && this.schedulesOff[output_num]){
            this.errorMsg.on = this.errorMsg_3;
            this.errorMsg.off = '';
        }else if (time_field === 'on'){
            this.errorMsg.on = this.schedulesOn[output_num]
                ? ''
                : this.errorMsg_1;
        } else {
            this.errorMsg.off = this.schedulesOff[output_num]
                ? ''
                : this.errorMsg_2;
        }

        this.$emit('updateSchedule', output_num, time_field, time_field === 'on' ? this.schedulesOn[output_num] : this.schedulesOff[output_num]);
    }

    calculateNewSchedules() {
        this.schedules = {timezone: this.timezone};
        Object.entries(this.schedulesOff).forEach(([output_num, off_time]) => {
            this.schedules[output_num] = {...this.schedules[output_num], is_astronomical: this.isAstronomical[output_num], active: this.activeSchedule[output_num]};
            if (this.isAstronomical[output_num]) {
                this.duskOffsetSign[output_num] = this.duskOffsetSign[output_num] || '+';
                this.duskOffsetHours[output_num] = this.duskOffsetHours[output_num] ? this.duskOffsetHours[output_num].padStart(2, '0') : '00';
                this.duskOffsetMinutes[output_num] = this.duskOffsetMinutes[output_num] ? this.duskOffsetMinutes[output_num].padStart(2, '0') : '00';
                this.dawnOffsetSign[output_num] = this.dawnOffsetSign[output_num] || '+';
                this.dawnOffsetHours[output_num] = this.dawnOffsetHours[output_num] ? this.dawnOffsetHours[output_num].padStart(2, '0') : '00';
                this.dawnOffsetMinutes[output_num] = this.dawnOffsetMinutes[output_num] ? this.dawnOffsetMinutes[output_num].padStart(2, '0') : '00';
                this.schedules[output_num] = {
                    ...this.schedules[output_num],
                    dawn: `${this.dawnOffsetSign[output_num]}${this.dawnOffsetHours[output_num]}:${this.dawnOffsetMinutes[output_num]}`,
                    dusk: `${this.duskOffsetSign[output_num]}${this.duskOffsetHours[output_num]}:${this.duskOffsetMinutes[output_num]}`
                };

            }else if (this.item.control.toString() === output_num){
                if (!off_time){
                    this.errorMsg.off = this.errorMsg_2;
                }else if (off_time !== this.schedulesOn[output_num]) {
                    this.schedules[output_num] = {...this.schedules[output_num], off: off_time};
                }
            }else {
                this.schedules[output_num] = {...this.schedules[output_num], off: off_time};
            }
        });
        Object.entries(this.schedulesOn).forEach(([output_num, on_time]) => {
            if (!this.isAstronomical[output_num]) {
                if (this.item.control.toString() === output_num){
                    if (!on_time){
                        this.errorMsg.on = this.errorMsg_1;
                    }else if (on_time === this.schedulesOff[output_num]){
                        this.errorMsg.on = this.errorMsg_3;
                        this.errorMsg.off = '';
                    }else {
                        this.schedules[output_num] = {...this.schedules[output_num], on: on_time};
                    }
                }else {
                    this.schedules[output_num] = {...this.schedules[output_num], on: on_time};
                }
                
            }
        });
    }
}

