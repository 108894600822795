import { render, staticRenderFns } from "./Contactor.vue?vue&type=template&id=1d4b860b&scoped=true"
import script from "./Contactor.vue?vue&type=script&lang=ts"
export * from "./Contactor.vue?vue&type=script&lang=ts"
import style0 from "./Contactor.vue?vue&type=style&index=0&id=1d4b860b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d4b860b",
  null
  
)

export default component.exports